<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>基础设置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/avatarFrame' }"
          >头像框配置
        </el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
        ref="form"
        :model="form"
        :rules="rules"
        label-width="200px"
        size="mini"
      >
        <el-form-item
          label="等级名称："
          label-position="left"
          prop="level_name"
        >
          <el-input v-model="form.level_name" style="width: 200px" />
        </el-form-item>

        <el-form-item
          label="最小打卡次数："
          label-position="left"
          prop="min_sign_count"
        >
          <el-input-number
            v-model="form.min_sign_count"
            :min="0"
            label="最小打卡次数"
            style="width: 200px"
          ></el-input-number>
        </el-form-item>

        <el-form-item
          label="最大打卡次数："
          label-position="left"
          prop="max_sign_count"
        >
          <el-input-number
            v-model="form.max_sign_count"
            :min="form.min_sign_count"
            label="最大打卡次数"
            style="width: 200px"
          ></el-input-number>
        </el-form-item>

        <el-form-item
          label="头像框："
          label-position="left"
          prop="avatar_frame"
        >
          <select-images
            :image="image"
            @onDelete="onDelete"
            @onSelect="onSelect"
          ></select-images>
        </el-form-item>

        <el-form-item>
          <el-button
            :loading="loading"
            size="small"
            type="primary"
            @click="onSubmit"
            >保存
          </el-button>
          <el-button
            size="small"
            style="margin-left: 10px"
            @click="$router.go(-1)"
            >取消
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import SelectImages from "@/components/selectImages/index.vue";

export default {
  name: "add",
  components: { SelectImages },
  data() {
    return {
      loading: false,
      form: {
        level_name: "",
        max_sign_count: 0,
        min_sign_count: 0,
        avatar_frame: "",
      },
      image: "",
      rules: {
        level_name: [
          { required: true, message: "请输入等级名称", trigger: "blur" },
          { max: 20, message: "长度在20以内字符", trigger: "blur" },
        ],
        min_sign_count: [
          { required: true, message: "请输入最小打卡次数", trigger: "blur" },
        ],
        max_sign_count: [
          { required: true, message: "请输入最大打卡次数", trigger: "blur" },
        ],
        avatar_frame: [
          { required: true, message: "请选择图片", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    ...mapActions("avatarFrame", ["levelAvatarFrameAdd"]),
    onSelect(image) {
      this.image = imageUrl + image.path;
      this.form.avatar_frame = image.path;
    },
    onDelete() {
      this.image = "";
      this.form.avatar_frame = "";
    },
    async onSubmit() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const { res_info } = await this.levelAvatarFrameAdd(this.form);
            if (res_info !== "ok") return;
            this.$message.success("创建成功");
            this.$router.go(-1);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
  align-items: center;
}
</style>